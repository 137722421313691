<template>
  <div>
    <form-filters :is-filters-sidebar-active.sync="isFiltersSidebarActive" titulo="Filtros"
      @aplicar-filtros="tratarEventoAplicarFiltros" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">Lista de Propostas</h5>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Exibição</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Pesquisar..." />
              <b-button v-if="!quantidadeFiltrosAplicados" class="mr-1" variant="primary"
                @click="tratarEventoSideBarFiltros()">
                <span class="text-nowrap">
                  Filtros ({{ quantidadeFiltrosAplicados }})
                </span>
              </b-button>
              <b-dropdown v-else :text="`Filtros (${quantidadeFiltrosAplicados})`" variant="primary" class="mr-1">
                <b-dropdown-item @click="tratarEventoSideBarFiltros()" href="#">Editar</b-dropdown-item>
                <b-dropdown-item href="#" @click="removerFiltros">Limpar</b-dropdown-item>
              </b-dropdown>
              <b-button variant="success" :to="{ name: 'propostas-create' }">
                <span class="text-nowrap"> Adicionar </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refUserListTable" class="position-relative font-size-9" :items="fetchDados" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
        empty-text="Nenhum resultado encontrado" :sort-desc.sync="isSortDirDesc" :busy="isLoad" striped>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>
        <template #cell(codigo_proposta)="data">
          <div class="column__w250">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar v-if="data.item.cliente" size="4.5em" :src="data.item.cliente.imagem"
                  :text="avatarText(data.item.cliente.razao_social)" :variant="'secondary'"
                  class="avatar-with-shadow" />
                <b-avatar v-else size="4.5em" src="" :text="avatarText(data.item.lead.razao_social)"
                  :variant="'secondary'" class="avatar-with-shadow">
                </b-avatar>
              </template>
              <div class="font-weight-bold">
                {{ data.item.codigo_proposta }}
              </div>
              <div class="font-size-7" v-if="data.item.cliente && data.item.cliente.razao_social">
                {{ data.item.cliente.razao_social }}
              </div>
              <div class="font-size-7" v-if="data.item.lead && data.item.lead.razao_social">
                {{ data.item.lead.razao_social }}
              </div>
              <div class="font-size-8 text-primary">
                <strong>SOLICITADO: </strong>{{ moment(data.item.data_solicitacao).format('DD/MM/YYYY') }}
              </div>
            </b-media>
          </div>
        </template>

        <template #cell(itens)="data">
          <div>
            <tag-color v-for="item in data.item.itens" :key="item.disciplina_id" v-b-tooltip.hover.top.v-primary
              class="mb-1 cursor" :texto="item.disciplina.abreviacao" :title="item.disciplina.disciplina_nome"
              :bg-color="item.disciplina.bg_color" />
          </div>
        </template>

        <template #cell(status)="data">
          <div>
            <tag-color v-b-tooltip.hover.top.v-primary class="mb-1 cursor" :texto="data.item.status.abreviacao"
              :title="data.item.status.descricao" :bg-color="'#336195'" />
          </div>
        </template>

        <template #cell(data_entrega)="data">
          <div>
            <small>{{
              moment(data.item.data_entrega).format('DD/MM/YYYY')
            }}</small>
          </div>
        </template>
        <template #cell(valor)="data">
          <div class="font-size-8">
            {{ formatarParaMoedaBrasileira(data.item.valor) }}
          </div>
        </template>
        <template #cell(tipo_custo)="data">
          <div class="font-size-8 text-primary">
            <strong>Tipo: </strong>{{ data.item.tipo_custo }}
          </div>
          <div class="font-size-8 text-primary">
            <strong>Qtd Subs: </strong>{{ data.item.subdisciplinas_count }}
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>


            <b-dropdown-item :to="{ name: 'propostas-editar', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item @click="abrirModalPropostaSimplificada(data.item)">
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Prop. Simplificada</span>
            </b-dropdown-item>
            <hr>
            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos
              {{ dataMeta.of }} resultados encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalItens" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal id="modal-proposta-simplificada" centered
      :title="`Gerar PDF da Proposta Simplificada: ${modalPropostaSimplificada.codigo_proposta}`" size="md" hide-footer>
      <div class="d-flex flex-column align-items-center justify-content-center pt-2">
        <div class="d-flex mb-2 mr-2 align-items-center">
          <b-button variant="primary" size="md"
            @click="gerarPropostaSimplificadaBtn(modalPropostaSimplificada,'completo')">
            <feather-icon class="font-large-1 mb-1" icon="PrinterIcon" /> COMPLETA
          </b-button>
          <div class="ml-2" style="width: 300px;">Versão completa com todos os valores detalhados.</div>
        </div>
        <div class="d-flex mb-2 mr-2 align-items-center">
          <b-button variant="outline-primary" size="md"  @click="gerarPropostaSimplificadaBtn(modalPropostaSimplificada,'resumido')">
            <feather-icon class="font-large-1 mb-1" icon="PrinterIcon" /> RESUMIDA
          </b-button>
          <div class="ml-2" style="width: 300px;">Versão resumida onde não mostra os valores das disciplinas.</div>
        </div>
      </div>

    </b-modal>
    <modalConfirmacao id="modal-confirmacao" titulo="Excluir Item" :item-id="dadosItem.id" :item-nome="dadosItem.nome"
      @itemConfirmado="excluirItem" @click="modalExcluir(dadosItem)" />
  </div>
</template>

<script>
  import {
    BAvatar,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BMedia,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    VBTooltip,
    BModal,
  } from 'bootstrap-vue'
  import { onUnmounted, ref } from '@vue/composition-api'
  import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
  import store from '@/store'
  import vSelect from 'vue-select'
  import { avatarText, formatarParaMoedaBrasileira } from '@core/utils/filter'
  import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
  import FormFilters from './FormFilters.vue'
  import registroStoreModule from '../propostaStoreModule'
  import useItemList from './useItemList'
  import TagColor from '@/views/components/componentesPensou/TagColor.vue'
  import moment from 'moment-timezone'
  import Swal from 'sweetalert2'

  export default {
    components: {
      TagColor,
      BMedia,
      BAvatar,
      BFormGroup,
      SelectComPesquisa,
      VBTooltip,
      FormFilters,
      modalConfirmacao,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BDropdown,
      BDropdownItem,
      BPagination,
      BSpinner,
      vSelect,
      BModal,
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    setup() {
      const ITEM_APP_STORE_MODULE_NAME = 'propostas'

      // Register module
      if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME))
        store.registerModule(ITEM_APP_STORE_MODULE_NAME, registroStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ITEM_APP_STORE_MODULE_NAME))
          store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
      })

      const {
        fetchDados,
        fetchUsuarios,
        tableColumns,
        perPage,
        currentPage,
        totalItens,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        filters,
        refUserListTable,
        refetchData,
        deleteItem,
        gerarPropostaSimplificada
      } = useItemList()

      return {
        deleteItem,
        fetchDados,
        tableColumns,
        perPage,
        currentPage,
        totalItens,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        filters,
        refUserListTable,
        refetchData,
        fetchUsuarios,
        avatarText,
        moment,
        gerarPropostaSimplificada
      }
    },
    data() {
      return {
        isFiltersSidebarActive: false,
        modalShow: false,
        modalPropostaSimplificada: {},
        dadosItem: {
          nome: '',
          uuid: '',
        },
      }
    },
    computed: {
      isLoad() {
        return this.$store.state['propostas'].load
      },
      quantidadeFiltrosAplicados() {
        const filters = this.$store.getters['propostas/getFilters']
        return Object.keys(filters).filter(
          (key) =>
            filters[key] !== null &&
            filters[key] !== '' &&
            filters[key] !== 'R$ 0,00',
        ).length
      },
    },
    methods: {
      formatarParaMoedaBrasileira,

      modalExcluir(item) {
        this.dadosItem.nome = item.codigo_proposta
        this.dadosItem.id = item.id
        this.$root.$emit('bv::show::modal', 'modal-confirmacao')
      },
      excluirItem(dados) {
        this.deleteItem(dados)
      },
      removerFiltros() {
        this.$store.commit('propostas/resetFilters')
        this.filters = {}
        this.refetchData()
      },
      tratarEventoSideBarFiltros() {
        this.isFiltersSidebarActive = !this.isFiltersSidebarActive
      },
      tratarEventoAplicarFiltros(filters) {
        this.filters = filters
        this.refetchData()
      },
      abrirModalPropostaSimplificada(item) {
        this.modalPropostaSimplificada = {
          ...item
        }
        this.$root.$emit('bv::show::modal', 'modal-proposta-simplificada')
      },
      gerarPropostaSimplificadaBtn(item, tipo) {
        this.$root.$emit('bv::hide::modal', 'modal-proposta-simplificada')
        this.loadingGerarProposta(item)
        this.gerarPropostaSimplificada(item,tipo)
      },

      loadingGerarProposta(item) {
        Swal.fire({
          title: `Gerando proposta ${item.codigo_proposta}`,
          html: 'O arquivo está sendo gerado, aguarde...',
          icon: 'info',
          didOpen: () => {
            Swal.showLoading()
          },
          backdrop: false,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';

  .per-page-selector {
    width: 90px;
  }
</style>
