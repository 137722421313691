import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { usePagination } from '@/commons/hooks/pagination/use-pagination'
import { PaginationStateKeys } from '@/commons/stores/pagination/pagination'
import Swal from 'sweetalert2'

export default function useItemList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'codigo_proposta',
      label: 'Proposta',

    },
    {
      key: 'nome',
      label: 'nome',
      class: 'font-size-8',
    },
    {
      key: 'itens',
      label: 'disciplinas',
    },
    {
      key: 'tipo_custo',
      label: 'Subdisciplinas',
    },
    {
      key: 'valor',
      label: 'Valor',
      class: 'text-center',
      sortable: true,
      thStyle: { width: '20%' },

    },
    {
      key: 'data_entrega',
      label: 'Entrega',
      class: 'text-center',
    },
    {
      key: 'status',
      label: 'Status',
      class: 'text-center',
    },
    {
      key: 'Ações',
      class: 'text-center',
    },
  ]

  const filters = ref({})

  const {
    perPage,
    totalItens,
    currentPage,
    perPageOptions,
    searchQuery,
    isSortDirDesc,
    sortBy,
  } = usePagination({
    id: PaginationStateKeys.GRID_PROPOSTAS,
    sortBy: 'id',
    isSortDirDesc: true,
  })

  const dataMeta = computed(() => {
    // eslint-disable-next-line no-unused-expressions
    currentPage.value - 1

    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const getFilters = () => {
    Object.keys(filters.value).forEach((key) => {
      if (!filters.value[key]) {
        delete filters.value[key]
      }
    })
  }

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    getFilters()
    store
      .dispatch('propostas/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        ...filters.value,
      })
      .then((response) => {
        callback(response.data)
        totalItens.value = response.data.totalItens
      })
  }

  const deleteItem = (id) => {
    store.dispatch('propostas/deleteItem', id).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Proposta excluída com sucesso',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      refetchData()
    })
  }

  const create = (item) => {
    store.dispatch('propostas/store', item).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Proposta criada com sucesso',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      router.push({ name: 'propostas' })
    })
  }

  const update = (item) => {
    store.dispatch('propostas/update', item).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Proposta editada com sucesso ',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      router.push({ name: 'propostas' })
    })
  }

  const alterarStatus = (item) => {
    store.dispatch('propostas/alterarStatus', item).then(() => {
      window.history.back()

      toast({
        component: ToastificationContent,
        props: {
          title: 'Status alterado com sucesso ',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    })
  }
  
  const getItem = (id) => {
    return store.dispatch('propostas/getItem', id)
  }

  //Inicio do download do arquivo
  function safeDecodeBase64(base64String) {
    const base64EncodedData = base64String.split(',')[1] || base64String

    if (!/^[A-Za-z0-9+/=]+$/.test(base64EncodedData)) {
      console.error('Base64 é inválido.')
      return null
    }

    try {
      return atob(base64EncodedData)
    } catch (error) {
      console.error('Erro no decoding do Base64:', error)
      return null
    }
  }

   function downloadPdf(base64Data, fileName) {
    const decodedData = safeDecodeBase64(base64Data)
    if (!decodedData) {
      console.error('Failed to decode Base64 data.')
      return
    }

    const byteNumbers = new Array(decodedData.length)
    for (let i = 0; i < decodedData.length; i++) {
      byteNumbers[i] = decodedData.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const fileBlob = new Blob([byteArray], { type: 'application/pdf' })

    const blobUrl = URL.createObjectURL(fileBlob)
    const downloadLink = document.createElement('a')
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.href = blobUrl
    downloadLink.download = fileName
    downloadLink.click()
    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(blobUrl)
  }
  const gerarPropostaSimplificada = (item,tipo) => {

    item.tipo = tipo
    store.dispatch('propostas/gerarPropostaSimplificada', item)
      .then(resp => {
        const base64Data = resp.data.file
        const fileName = `${resp.data.name}-${tipo}.pdf`
        downloadPdf(base64Data, fileName)
        Swal.close()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Proposta simplificada completa gerada com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        console.error('Error generating PDF:', error)
      })
  }


  return {
    fetchDados,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    filters,
    refUserListTable,
    refetchData,
    update,
    alterarStatus,
    create,
    getItem,
    gerarPropostaSimplificada,
  }
}
